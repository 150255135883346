body,
html {
	display: block;
	margin: 0;
	padding: 0;
	min-height: 100vh;
	box-sizing: border-box;
}

body {
	font-family: Arial, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.4;
	text-align: justify;
	background-color: #000;
	background-image: none;
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSI+CjxyZWN0IHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCIgZmlsbD0iIzI4MjgyOCI+PC9yZWN0Pgo8Y2lyY2xlIGN4PSIzIiBjeT0iNC4zIiByPSIxLjgiIGZpbGw9IiMzOTM5MzkiPjwvY2lyY2xlPgo8Y2lyY2xlIGN4PSIzIiBjeT0iMyIgcj0iMS44IiBmaWxsPSJibGFjayI+PC9jaXJjbGU+CjxjaXJjbGUgY3g9IjEwLjUiIGN5PSIxMi41IiByPSIxLjgiIGZpbGw9IiMzOTM5MzkiPjwvY2lyY2xlPgo8Y2lyY2xlIGN4PSIxMC41IiBjeT0iMTEuMyIgcj0iMS44IiBmaWxsPSJibGFjayI+PC9jaXJjbGU+Cjwvc3ZnPg=='),
		none;
}

h1 {
	font-family: 'Arial Black', Arial, sans-serif;
	font-weight: 700;
	font-size: 200%;
}

main {
	max-width: 1000px;
	background-color: #fcfbe3;
	background-image: none;
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNTAwIiBoZWlnaHQ9IjUwMCI+CjxmaWx0ZXIgaWQ9Im4iPgo8ZmVUdXJidWxlbmNlIHR5cGU9ImZyYWN0YWxOb2lzZSIgYmFzZUZyZXF1ZW5jeT0iLjciIG51bU9jdGF2ZXM9IjEwIiBzdGl0Y2hUaWxlcz0ic3RpdGNoIj48L2ZlVHVyYnVsZW5jZT4KPC9maWx0ZXI+CjxyZWN0IHdpZHRoPSI1MDAiIGhlaWdodD0iNTAwIiBmaWxsPSIjRkNGQkUzIj48L3JlY3Q+CjxyZWN0IHdpZHRoPSI1MDAiIGhlaWdodD0iNTAwIiBmaWx0ZXI9InVybCgjbikiIG9wYWNpdHk9IjAuNCI+PC9yZWN0Pgo8L3N2Zz4='),
		none;
	margin: 20px;
}

div.content {
	padding: 2em;
}

aside.photo {
	max-width: 360px;
	flex: 0 0 auto;
	margin: auto;
}

ul.social-links {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

ul.social-links svg {
	height: 40px;
	width: 40px;

	/* fill: transparent; */
}

@media (width >= 1000px) {
	body {
		display: flexbox;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 2em;
	}

	div.container {
		display: box;
		display: flexbox;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 2em;
	}

	div.content {
		flex: 1 1 auto;
		padding: 0;
	}

	aside.photo {
		width: 360px;
		position: relative;
		left: 4em;
		top: -4em;
	}
}

figure.polaroid {
	margin: 16px;
	padding: 16px;
	text-align: center;
	background: #fff;
	background: linear-gradient(white, #fcfcfc 20%, #f2ebde 60%);
	box-shadow: 6px 6px 12px -6px rgb(0 0 0 / 75%);
	transition: all 0.5s;
}

figure.polaroid.rotate-2 {
	transform: rotate(2deg);
}

figure.polaroid figcaption {
	font-family: 'Permanent Marker', cursive;
	letter-spacing: 0.11em;
	color: rgb(40 66 131 / 90%);
}

figure.polaroid:hover {
	transform: rotate(0);
	box-shadow: 12px 12px 24px -12px rgb(0 0 0 / 75%);
}

.social-links li {
	text-align: center;
}

.icon {
	width: 30px;
	height: 30px;
}

.symbols {
	display: none;
}
