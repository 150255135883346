body, html {
  box-sizing: border-box;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  display: block;
}

body {
  text-align: justify;
  background-color: #000;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSI+CjxyZWN0IHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCIgZmlsbD0iIzI4MjgyOCI+PC9yZWN0Pgo8Y2lyY2xlIGN4PSIzIiBjeT0iNC4zIiByPSIxLjgiIGZpbGw9IiMzOTM5MzkiPjwvY2lyY2xlPgo8Y2lyY2xlIGN4PSIzIiBjeT0iMyIgcj0iMS44IiBmaWxsPSJibGFjayI+PC9jaXJjbGU+CjxjaXJjbGUgY3g9IjEwLjUiIGN5PSIxMi41IiByPSIxLjgiIGZpbGw9IiMzOTM5MzkiPjwvY2lyY2xlPgo8Y2lyY2xlIGN4PSIxMC41IiBjeT0iMTEuMyIgcj0iMS44IiBmaWxsPSJibGFjayI+PC9jaXJjbGU+Cjwvc3ZnPg=="), none;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
}

h1 {
  font-family: Arial Black, Arial, sans-serif;
  font-size: 200%;
  font-weight: 700;
}

main {
  background-color: #fcfbe3;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNTAwIiBoZWlnaHQ9IjUwMCI+CjxmaWx0ZXIgaWQ9Im4iPgo8ZmVUdXJidWxlbmNlIHR5cGU9ImZyYWN0YWxOb2lzZSIgYmFzZUZyZXF1ZW5jeT0iLjciIG51bU9jdGF2ZXM9IjEwIiBzdGl0Y2hUaWxlcz0ic3RpdGNoIj48L2ZlVHVyYnVsZW5jZT4KPC9maWx0ZXI+CjxyZWN0IHdpZHRoPSI1MDAiIGhlaWdodD0iNTAwIiBmaWxsPSIjRkNGQkUzIj48L3JlY3Q+CjxyZWN0IHdpZHRoPSI1MDAiIGhlaWdodD0iNTAwIiBmaWx0ZXI9InVybCgjbikiIG9wYWNpdHk9IjAuNCI+PC9yZWN0Pgo8L3N2Zz4="), none;
  max-width: 1000px;
  margin: 20px;
}

div.content {
  padding: 2em;
}

aside.photo {
  flex: none;
  max-width: 360px;
  margin: auto;
}

ul.social-links {
  justify-content: space-around;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

ul.social-links svg {
  width: 40px;
  height: 40px;
}

@media (width >= 1000px) {
  body {
    display: flexbox;
    justify-content: center;
    align-items: center;
    padding: 2em;
    display: flex;
  }

  div.container {
    display: box;
    display: flexbox;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2em;
    display: flex;
  }

  div.content {
    flex: auto;
    padding: 0;
  }

  aside.photo {
    width: 360px;
    position: relative;
    top: -4em;
    left: 4em;
  }
}

figure.polaroid {
  text-align: center;
  background: linear-gradient(#fff, #fcfcfc 20%, #f2ebde 60%);
  margin: 16px;
  padding: 16px;
  transition: all .5s;
  box-shadow: 6px 6px 12px -6px #000000bf;
}

figure.polaroid.rotate-2 {
  transform: rotate(2deg);
}

figure.polaroid figcaption {
  letter-spacing: .11em;
  color: #284283e6;
  font-family: Permanent Marker, cursive;
}

figure.polaroid:hover {
  transform: rotate(0);
  box-shadow: 12px 12px 24px -12px #000000bf;
}

.social-links li {
  text-align: center;
}

.icon {
  width: 30px;
  height: 30px;
}

.symbols {
  display: none;
}
/*# sourceMappingURL=index.046bef92.css.map */
